import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

const img1 = "https://www.chinmayavvdelhi.ac.in/images/Blackele/unnamed.png";
const img2 = "https://www.chinmayavvdelhi.ac.in/images/Blackele/chinmaya.png";
const img3 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Swami_Tejomayananda.png";
const img4 = "https://www.chinmayavvdelhi.ac.in/images/Blackele/9344.png";
const img5 =
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Cmb7wcUVYAET7PB.png";

export default function ChinmayaMissionPage() {
  return (
    <Layout>
      <SEO
        title="Central Chinmaya Mission Trust | Chinmaya Vidyalaya Vasant Vihar"
        description="Chinmaya Mission was established in 1953 by devotees of the world-renowned Vedanta teacher, his holiness Swami Chinmayananda, and is guided by his vision."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="academics chinmaya vidyalaya, Chinmaya vidyalaya delhi,Chinmaya vidyalaya vasant vihar, chinmaya trust, chinmaya mission, online vidyalaya,online admission,schools in delhi,cbse schools in delhi"
      />
      <div className="chinmayamission">
        <section className="chinmayamission__section1">
          <h1 className="heading--white">
            Central Chinmaya Mission Trust (CCMT)
          </h1>

          <p className="chinmayamission__text">
            Chinmaya Mission® was established in 1953 by devotees of the
            world-renowned Vedanta teacher, His Holiness Swami Chinmayananda,
            and is guided by His vision.
            <br />
            <br />
            The word ‘Chinmaya’ means ‘true knowledge’. The Chinmaya Mission® is
            dedicated to global spiritual upliftment and Indian cultural
            renaissance. It is an organization where seekers of the Truth come
            together to acquire and impart the ancient teachings of the
            Upanishads.
            <br />
            <br />
            A vital and unique contribution of Chinmaya Mission to the world at
            large is that it gives the logic of spirituality and the foundation
            of Vedic culture in the teaching tradition of Advaita Vedanta, the
            ageless wisdom of universal oneness. Vedanta, the universal science
            of life, is at the heart of Hinduism, linking the sacred to the
            secular. It is relevant to people of all backgrounds and faiths,
            inspiring all seekers alike.
            <br />
            <br />
            The global governing body of the Mission is the Central Chinmaya
            Mission Trust (CCMT), Mumbai, presently headed by His Holiness Swami
            Swaroopananda. The Mission has over 313 centres all over the world
            which organize and coordinate the spiritual, cultural, educational
            and social service projects and activities of the Mission. More than
            an organization, Chinmaya is a movement that touches all aspects of
            human life and is therefore relevant at all times.
          </p>

          <div className="chinmayamission__buttons">
            <a
              target="_blank"
              className="btn-white"
              href="https://www.chinmayamission.com/"
            >
              More About The Mission
            </a>

            <Link className="btn-white" to="/">
              Chinmaya Initiatives
            </Link>
          </div>
        </section>
        <section className="chinmayamission__section2">
          <h2 className="heading">Our Guiding Lights</h2>

          <div className="chinmayamission__section2__images">
            <img
              className="chinmayamission__section2__images__img"
              src={img1}
            ></img>
            <img
              className="chinmayamission__section2__images__img"
              src={img2}
            ></img>
            <img
              className="chinmayamission__section2__images__img"
              src={img3}
            ></img>
            <img
              className="chinmayamission__section2__images__img"
              src={img4}
            ></img>
            <img
              className="chinmayamission__section2__images__img"
              src={img5}
            ></img>
          </div>

          <div className="chinmayamission__section2__heading1">Parama Guru</div>
          <div className="chinmayamission__section2__heading2">
            Pujya Swami Tapovanam
          </div>

          <p className="chinmayamission__section2__text">
            Swami Tapovan Maharaj was a consummate Vedantine, a strict teacher,
            poet and a sage of great wisdom and tranquility. He undertook the
            pilgrimage to Mount Kailash on foot and wrote inspiringly of the
            divine experiences of his wandering in the Himalayas.
            <br />
            <br />
            He was that pristine glacier of self-knowledge from whom flowed the
            Ganga of Vedantic wisdom through Swami Chinmayananda, the Founder of
            Chinmaya Mission. Swami Sivananda called him ‘Himavat Vibhuti,’
            meaning “the glory of the Himalayas”. It is to his credit that India
            saw a saint like Pujya Gurudev, who often said that the Mission and
            its activities were the blessings and gift of his Guru to all of
            humanity.
            <br />
            <br />
            Swami Chinmayananda said of his Guru, “He was a God without temple,
            a Veda without language”.
          </p>
        </section>
      </div>
    </Layout>
  );
}
